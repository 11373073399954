"use client";

import {metaService} from "@/service/meta.service";
import {createContext, useContext, useEffect, useState} from "react";
const NeoContext = createContext<NeoContextProps>({} as NeoContextProps);

/**
 * Suggestion
 *
 *  We already have sufficient props for now, only add a new prop if its really required
 *  please extend type context
 *
 */

export const NeoContextProvider = ({children}: NeoContextProviderProps) => {
  const [operators, setOperators] = useState<OperatorLookup[]>([]);
  const [categories, setCategories] = useState<CategoryLookup[]>([]);
  const [vehicles, setVehicles] = useState<VehicleInfo[]>([]);
  const [facility, setFacility] = useState<Facility[]>([]);
  const [offset, setoffset] = useState("");
  const [clients, setClients] = useState<Client[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<IFilterForm>({
    institution: undefined
  });

  const [filterContext, setFilterContext] = useState<FilterContext>({
    client: {
      institutionId: null,
      institutionUserId: null,
      institutionUserName: null,
      institutionName: null,
      institutionUserPhone: null,
      name: null,
      roles: []
    }
  });

  const fetchData = async () => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const offsetInMinutes = new Date().getTimezoneOffset();
    const positiveOffsetInMinutes = Math.abs(offsetInMinutes);
    const offsetInSeconds: any = positiveOffsetInMinutes * 60;
    setoffset(offsetInSeconds);
    await metaService.onGetClientProfile().then((response: NeoClients) => {
      setOperators(response.lookup.operators!);
      setCategories(response.lookup.categories!);
      setVehicles(response.lookup.vehicles!);
      setFacility(response.lookup.facility!);
      setClients(response.clients);
      setFilterContext({
        client: response?.default
      });
      setSelectedFilter({
        institution: response.default.institutionUserId!
      });
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <NeoContext.Provider
      value={{
        categories,
        operators,
        clients,
        vehicles,
        filterContext,
        setFilterContext,
        selectedFilter,
        setSelectedFilter,
        offset
      }}
    >
      {children}
    </NeoContext.Provider>
  );
};
export const useNeoContext = () => useContext<NeoContextProps>(NeoContext);
