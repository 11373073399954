export const DashboardReport: DASHBOARDREPORTCONFIG = [
  {
    header: "Dashboard Details",
    description: "",
    order: 1,
    menus: [
      {
        icon: "",
        menu: "Vehicle Status",
        menu_description: "",
        url: "/api/vehicle/status",
        method: "GET",
        filters: [],
        columns: [
          {
            uid: "date",
            name: "Date"
          },
          {
            uid: "instituteName",
            name: "Institute"
          },
          {
            uid: "vehicleName",
            name: "Vehicle/Name"
          },
          {
            uid: "vehicleStatus",
            name: "Vehicle Status"
          },
          {
            uid: "hardwareStatus",
            name: "Hardware Status"
          },
          {
            uid: "lastContactTime",
            name: "Last Contact Time"
          }
        ],
        activeColumns: [
          "date",
          "instituteName",
          "vehicleName",
          "vehicleStatus",
          "hardwareStatus",
          "lastContactTime"
        ]
      },
      {
        icon: "",
        menu: "Trip Status",
        menu_description: "",
        url: "/api/trip/status",
        method: "GET",
        filters: [],
        columns: [
          {
            uid: "date",
            name: "Date"
          },
          {
            uid: "instituteName",
            name: "Institute"
          },
          {
            uid: "vehicleName",
            name: "Vehicle/Name"
          },
          {
            uid: "tripName",
            name: "Route"
          },
          {
            uid: "tripType",
            name: "Trip Type"
          },
          {
            uid: "tripStartTime",
            name: "Start Time"
          },
          {
            uid: "tripEndTime",
            name: "End Time"
          },
          {
            uid: "tripStatus",
            name: "Status"
          }
        ],
        activeColumns: [
          "date",
          "instituteName",
          "vehicleName",
          "tripName",
          "tripType",
          "tripStartTime",
          "tripEndTime",
          "tripStatus"
        ]
      },
      {
        icon: "",
        menu: "Speed Deviation",
        menu_description: "",
        url: "/api/deviation/speed",
        method: "GET",
        filters: [],
        columns: [
          {
            uid: "date",
            name: "Date"
          },
          {
            uid: "instituteName",
            name: "Institute"
          },
          {
            uid: "vehicleName",
            name: "Vehicle/Name"
          },
          {
            uid: "threshold",
            name: "Limit"
          },
          {
            uid: "count",
            name: "Count"
          }
        ],
        activeColumns: [
          "date",
          "instituteName",
          "vehicleName",
          "threshold",
          "count"
        ]
      },
      {
        icon: "",
        menu: "Distance Driven",
        menu_description: "",
        url: "/api/distance/driven",
        method: "GET",
        filters: [],
        columns: [
          {
            uid: "date",
            name: "Date"
          },
          {
            uid: "instituteName",
            name: "Institute"
          },
          {
            uid: "vehicleName",
            name: "Vehicle/Name"
          },
          {
            uid: "scheduled",
            name: "Scheduled Kms"
          },
          {
            uid: "unScheduled",
            name: "Unscheduled Kms"
          }
        ],
        activeColumns: [
          "date",
          "instituteName",
          "vehicleName",
          "scheduled",
          "unScheduled"
        ]
      },
      {
        icon: "",
        menu: "Distance Deviation",
        menu_description: "",
        url: "/api/deviation/distance",
        method: "GET",
        filters: [],
        columns: [
          {
            uid: "date",
            name: "Date"
          },
          {
            uid: "instituteName",
            name: "Institute"
          },
          {
            uid: "vehicleName",
            name: "Vehicle/Name"
          },
          {
            uid: "threshold",
            name: "Limit"
          },
          {
            uid: "totalDistance",
            name: "Distance"
          },
          {
            uid: "deviationTime",
            name: "Deviation Time"
          }
        ],
        activeColumns: [
          "date",
          "instituteName",
          "vehicleName",
          "threshold",
          "totalDistance",
          "deviationTime"
        ]
      },
      {
        icon: "",
        menu: "Bus Attendance",
        menu_description: "",
        url: "/api/busAttendance",
        method: "GET",
        filters: [],
        columns: [
          {
            uid: "date",
            name: "Date"
          },
          {
            uid: "instituteName",
            name: "Institute"
          },
          {
            uid: "name",
            name: "Name"
          },
          {
            uid: "className",
            name: "Class"
          },
          {
            uid: "enrollNo",
            name: "Enroll No"
          },
          {
            uid: "in_pick_time",
            name: "Pickup"
          },
          {
            uid: "out_drop_time",
            name: "Drop"
          },
          {
            uid: "status",
            name: "Status"
          }
        ],
        activeColumns: [
          "date",
          "instituteName",
          "name",
          "className",
          "enrollNo",
          "in_pick_time",
          "out_drop_time",
          "status"
        ]
      },
      {
        icon: "",
        menu: "Campus Attendance",
        menu_description: "",
        url: "/api/v2/campusAttendance",
        method: "GET",
        filters: [],
        columns: [
          {
            uid: "date",
            name: "Date"
          },
          {
            uid: "instituteName",
            name: "Institute"
          },
          {
            uid: "name",
            name: "Name"
          },
          {
            uid: "className",
            name: "Class"
          },
          {
            uid: "enrollNo",
            name: "Enroll No"
          },
          {
            uid: "in_pick_time",
            name: "In Time"
          },
          {
            uid: "out_drop_time",
            name: "Out Time"
          },
          {
            uid: "status",
            name: "Status"
          }
        ],
        activeColumns: [
          "date",
          "instituteName",
          "name",
          "className",
          "enrollNo",
          "in_pick_time",
          "out_drop_time",
          "status"
        ]
      },
      {
        icon: "",
        menu: "Insurance Summary",
        menu_description: "",
        url: "api/v2/insurance/summary",
        method: "GET",
        filters: [],
        columns: [
          {
            uid: "institutionName",
            name: "Institute"
          },
          {
            uid: "vehicleName",
            name: "Vehicle Name"
          },
          {
            uid: "regNo",
            name: "RegNo"
          },
          {
            uid: "validUpto",
            name: "Valid Upto"
          },
          {
            uid: "documentNumber",
            name: "Document Number"
          },
          {
            uid: "issuerName",
            name: "Issuer Name"
          },
          {
            uid: "status",
            name: "Status"
          }
        ],
        activeColumns: [
          "institutionName",
          "vehicleName",
          "regNo",
          "validUpto",
          "documentNumber",
          "issuerName",
          "status"
        ]
      },
      {
        icon: "",
        menu: "Permit Summary",
        menu_description: "",
        url: "api/v2/permit/summary",
        method: "GET",
        filters: [],
        columns: [
          {
            uid: "institutionName",
            name: "Institute"
          },
          {
            uid: "vehicleName",
            name: "vehicle Name"
          },
          {
            uid: "regNo",
            name: "RegNo"
          },
          {
            uid: "validUpto",
            name: "Valid Upto"
          },
          {
            uid: "documentNumber",
            name: "Document Number"
          },
          {
            uid: "issuerName",
            name: "Issuer Name"
          },
          {
            uid: "status",
            name: "Status"
          }
        ],
        activeColumns: [
          "institutionName",
          "vehicleName",
          "regNo",
          "validUpto",
          "documentNumber",
          "issuerName",
          "status"
        ]
      }
    ]
  }
];
