/**
 * An array of routes that are accessible to the public.
 * These routes do not require authentication.
 * @type {string[]}
 */
export const PublicRoutes: string[] = ["/"];

/**
 * An array of routes that are used for authentication.
 * @type {string[]}
 */
export const AuthRoutes: string[] = [
  "/auth/login",
  "/auth/forgot-password",
  "/auth/reset-password"
];

export const SessioAuthRoutes: string[] = ["/auth/"];

export const AuthSessionLoginURL: string =
  "https://track.neotrackweb.com/api_v2/authentication/getSession.php";

/**
 * The prefix for API authentication routes.
 * Routes that start with this prefix are used for API authentication purposes.
 * @type {string}
 */
export const AuthPrefix: string = "/api/auth";

/**
 * The default redirect path after logging in.
 * @type {string}
 */
export const DashboardUrl: string = "/live";

/**
 * The default logging url.
 * @type {string}
 */
export const LoginUrl: string = "/auth/login";

/**
 * API whitelist
 * @type {string[]}
 */
export const ApiWhiteList: string[] = [
  "/api/login",
  "/api/forgotpassword",
  "/api/resetpasssword",
  AuthSessionLoginURL
];

/**
 * Action whitelist
 * @type {string[]}
 */
export const ActionWhiteList: string[] = [
  "create",
  "delete",
  "update",
  "reset"
];
