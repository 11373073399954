export const UserTableConfig: NeoTableConfig = {
  title: "Users",
  table: {
    columns: [
      {name: "Name", uid: "name", type: "name", sortable: true},
      {name: "Gender", uid: "gender", type: "value"},
      {name: "Parent Name", uid: "parentName", type: "value", sortable: true},
      {name: "Phone No", uid: "phone", type: "value"},
      {name: "User Type", uid: "userType", type: "value"},
      {
        name: "App Status",
        uid: "appStatus",
        type: "option"
      },
      {name: "RFID", uid: "rfid", type: "value"},
      {name: "Pickup", uid: "pickup", type: "pickupdrop"},
      {name: "Drop", uid: "drop", type: "pickupdrop"},
      {name: "Status", uid: "status", type: "status"},
      {name: "Actions", uid: "actions", type: "actions"}
    ],
    style: {
      height: "h-full"
    },
    activeColumns: [
      "name",
      "gender",
      "parentName",
      "phone",
      "userType",
      "appStatus",
      "rfid",
      "pickup",
      "drop",
      "status",
      "actions"
    ],
    meta: {
      id: 1,
      title: "Manage Users",
      description: "Total Users",
      hasSearch: true,
      hasUpload: true,
      hasDownload: false,
      hasAdd: false,
      hasActions: false,
      hasCheckbox: false,
      hasFilter: false,
      hasFooter: true,
      hasHeader: true,
      hasPagination: true,
      hasCreate: false
    },
    url: {
      page: {
        view: "users/${navId}/view/user-details",
        add: "users/new/create/user",
        edit: "users/${navId}/edit/user"
      },
      api: {
        upload: "/api/users/upload",
        download: "/api/users/download",
        delete: "/api/users/delete",
        get: "/api/users/list",
        deactivate: "/api/users/status/update",
        resetpassword: "/api/users/password/reset"
      }
    },
    actions: [
      {
        key: "edit",
        label: "Edit",
        type: "navigate",
        isVisible: false
      },
      {
        key: "smart_edit",
        label: "Smart Edit",
        type: "modal",
        isVisible: false
      },
      {
        key: "reset_rfid",
        label: "Reset RFID",
        type: "modal",
        isVisible: false
      },
      {
        key: "resetpassword",
        label: "Reset Password",
        type: "modal",
        isVisible: true
      },
      {
        key: "deactivate",
        label: "Deactivate",
        type: "modal",
        isVisible: false
      },
      {
        key: "delete",
        label: "Delete",
        type: "modal",
        isVisible: false
      }
    ]
  }
};
