export const AnnouncementUsers: NeoTableConfig = {
  title: "Users",
  table: {
    columns: [
      {name: "Name", uid: "name", type: "name", sortable: true},
      {name: "Class", uid: "class", type: "value", sortable: false},
      {name: "Enroll No", uid: "unique_stud_id", type: "value", sortable: false}
    ],
    style: {
      height: "h-full"
    },
    activeColumns: ["name", "class", "unique_stud_id"],
    meta: {
      hasCheckbox: true
      /*id: 1,
      title: "Manage Users",
      description: "Total Users",
      hasSearch: true,
      hasUpload: true,
      hasDownload: true,
      hasAdd: true,
      hasActions: true,
      hasCheckbox: false,
      hasFilter: true,
      hasFooter: true,
      hasHeader: true,
      hasPagination: true,
      hasCreate: true*/
    },
    url: {
      page: {},
      api: {}
    },
    actions: []
  }
};
