export const VehicleViewConfig: VehicleViewConfig = {
  cards: [
    {
      title: "Vehicle Details",
      type: "vehicle",
      fields: [
        {
          label: "Short Name",
          value: "NA",
          type: "text",
          key: "shortName",
          isVisible: false
        },
        {
          label: "Name",
          value: "NA",
          type: "text",
          key: "name",
          isVisible: false
        },
        {
          label: "Chassis No",
          value: "NA",
          type: "text",
          key: "chassis",
          isVisible: true
        },
        {
          label: "Engine No",
          value: "NA",
          type: "text",
          key: "engine",
          isVisible: true
        },
        {
          label: "Model",
          value: "NA",
          type: "text",
          key: "model",
          isVisible: true
        },
        {
          label: "Color",
          value: "NA",
          type: "text",
          key: "vehicleColour",
          isVisible: true
        },
        {
          label: "Fuel",
          value: "NA",
          type: "text",
          key: "type",
          isVisible: true
        },
        {
          label: "Manufacturing Year",
          value: "NA",
          type: "text",
          key: "vehicleManufacturingMonthYear",
          isVisible: true
        },
        {
          label: "Seating Capacity",
          value: "NA",
          type: "text",
          key: "vehicleSeatCapacity",
          isVisible: true
        },
        {
          label: "GPS System",
          value: "NA",
          type: "text",
          key: "gps",
          isVisible: false
        },
        {
          label: "GPS",
          value: "NA",
          type: "text",
          key: "gpstype",
          isVisible: false
        },
        {
          label: "Hardware No",
          value: "NA",
          type: "text",
          key: "trackerId",
          isVisible: false
        },
        {
          label: "Vehicle Type",
          value: "NA",
          type: "text",
          key: "type",
          isVisible: false
        },
        {
          label: "Fuel Type",
          value: "NA",
          type: "text",
          key: "fueltype",
          isVisible: false
        },
        {
          label: "Vehicle Category",
          value: "NA",
          type: "text",
          key: "category",
          isVisible: false
        },
        {
          label: "Seating Capacity",
          value: "NA",
          type: "text",
          key: "vehicleSeatCapacity	",
          isVisible: false
        },
        {
          label: "Operator",
          value: "NA",
          type: "text",
          key: "operator",
          isVisible: false
        },
        {
          label: "Contract Start Date",
          value: "NA",
          type: "date",
          key: "contractstartdate",
          isVisible: false
        },
        {
          label: "Contract End Date",
          value: "NA",
          type: "date",
          key: "contractenddate",
          isVisible: false
        },
        {
          label: "Driver Name",
          value: "NA",
          type: "text",
          key: "driverName",
          isVisible: true
        },
        {
          label: "Driver Phone No",
          value: "NA",
          type: "text",
          key: "driverphoneno",
          isVisible: false
        },
        {
          label: "Max Travel Duration(HH:MM)",
          value: "NA",
          type: "text",
          key: "maxtraveltime",
          isVisible: false
        },
        {
          label: "Max Travel Distance",
          value: "NA",
          type: "text",
          key: "maxtraveldistance",
          isVisible: false
        },
        {
          label: "Starting KMS",
          value: "NA",
          type: "text",
          key: "startingKms",
          isVisible: false
        },
        {
          label: "Vehicle Purchased Date",
          value: "NA",
          type: "date",
          key: "vehiclepurchaseddate",
          isVisible: false
        },
        {
          label: "Colour Palatte",
          value: "NA",
          type: "text",
          key: "colour",
          isVisible: false
        },
        {
          label: "Released Date",
          value: "NA",
          type: "date",
          key: "releaseddate",
          isVisible: false
        }
      ]
    },
    {
      title: "Compliance Details",
      type: "compliance",
      fields: [
        {
          label: "Registration Certificate No",
          value: "NA",
          type: "text",
          key: "regNo",
          isVisible: true
        },
        {
          label: "Registration Certificate Expiry",
          value: "NA",
          type: "date",
          key: "rcExpiryDate",
          isVisible: true
        },
        {
          label: "Pollution Certificate No",
          value: "NA",
          type: "text",
          key: "puccNumber",
          isVisible: true
        },
        // vehicleTaxUpto
        {
          label: "Pollution Expiry",
          value: "NA",
          type: "date",
          key: "puccUpto",
          isVisible: true
        },
        {
          label: "Insurance Company",
          value: "NA",
          type: "text",
          key: "vehicleInsuranceCompanyName",
          isVisible: true
        },
        {
          label: "Insurance No",
          value: "NA",
          type: "text",
          key: "vehicleInsurancePolicyNumber",
          isVisible: true
        },
        {
          label: "Insurance Expiry",
          value: "NA",
          type: "date",
          key: "vehicleInsuranceUpto",
          isVisible: true
        },
        {
          label: "Thirty Party Insurance No",
          value: "NA",
          type: "text",
          key: "thirdPartyInsuranceNo",
          isVisible: false
        },
        {
          label: "Thirty Party Insurance Expiry",
          value: "NA",
          type: "date",
          key: "thirdPartyInsuranceExpiryDate",
          isVisible: false
        },
        {
          label: "Permit No",
          value: "NA",
          type: "text",
          key: "permitNumber",
          isVisible: true
        },
        {
          label: "Permit Type",
          value: "NA",
          type: "text",
          key: "permitType",
          isVisible: true
        },
        {
          label: "Permit Valid From",
          value: "NA",
          type: "date",
          key: "permitValidFrom",
          isVisible: true
        },
        {
          label: "Permit Expiry",
          value: "NA",
          type: "date",
          key: "permitValidUpto",
          isVisible: true
        },
        {
          label: "Vehicle Tax Upto",
          value: "NA",
          type: "date",
          key: "vehicleTaxUpto",
          isVisible: true
        },
        {
          label: "Fitness Expiry",
          value: "NA",
          type: "date",
          key: "fitnessExpiryDate",
          isVisible: false
        },
        {
          label: "Road Tax Expiry",
          value: "NA",
          type: "date",
          key: "roadTaxExpiryDate",
          isVisible: false
        },
        {
          label: "Last Service Kms",
          value: "NA",
          type: "text",
          key: "lastServiceKms",
          isVisible: false
        },
        {
          label: "Last Service On",
          value: "NA",
          type: "date",
          key: "lastServiceDate",
          isVisible: false
        },
        {
          label: "Last Tyre Change Date",
          value: "NA",
          type: "date",
          key: "lastTyreChangedDate",
          isVisible: false
        }
      ]
    }
  ]
};
