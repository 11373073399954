/**
 *  Axios Interceptor to add  request & response headers
 *  Please restart the server, if you change this file in future
 *  changes won't impact as it is binded on server side
 */

import {ActionWhiteList, ApiWhiteList} from "@/libs/config/route.config";
import {SystemError} from "@/libs/consts";

import {getNeoCookie} from "@/libs/helper/cookies";
import axios, {AxiosInstance} from "axios";
import {getSession, signOut} from "next-auth/react";
import {destroyCookie} from "nookies";
import toast from "react-hot-toast";

let axiosClientInstance: AxiosInstance | null = null;
const baseURL = process.env.NEXT_PUBLIC_API_URL;
let lastSession: any | null = null;
let sessionPromise: any | null = null;

export const axiosInstance = (): AxiosInstance => {
  if (!axiosClientInstance) {
    axiosClientInstance = axiosClient();
  }
  return axiosClientInstance;
};
const axiosClient = (): AxiosInstance => {
  const instance = axios.create({
    baseURL,
    headers: {
      "Content-Type": "application/json"
    },
    withCredentials: false
  });

  instance.interceptors.request.use(
    async request => {
      if (
        !request.headers["Authorization"] &&
        !ApiWhiteList.includes(request.url as string)
      ) {
        const session = await fetchSession();
        request.headers["Access-Control-Allow-Origin"] = "*";
        if (session && session?.accessToken !== "") {
          request.headers.Authorization = `${session?.accessToken}`;
          //request.headers.userId = `${session?.userId}`;
          //request.headers.instituteId = `${session?.instituteId}`;
        } else {
          console.error("Token is invalid");
          destroyCookie({}, "neo.client", {path: "/"});
          const selectedClient: Client = await getNeoCookie("neo.client");
          if (selectedClient == null) {
            signOut();
          }
        }
      }
      return request;
    },
    error => {
      return Promise.reject(error);
    }
  );
  instance.interceptors.response.use(
    response => {
      const result: NeoResponse<any> = response?.data;
      if (
        ActionWhiteList.includes(response?.config?.url as string) &&
        result?.status === "success"
      ) {
        toast.success(result?.message!);
      }
      return response;
    },
    async error => {
      const prevRequest = error?.config;
      if (error?.isAxiosError) {
        if (error?.response) {
          const HttpStatusCode = error?.response?.status;
          const neoResponse: NeoResponse<any> = error.response?.data;
          if (HttpStatusCode === 401 && !prevRequest?.sent) {
            prevRequest.sent = true;
            lastSession = null;
            const session = await fetchSession();
            prevRequest.headers["Authorization"] = `${session?.accessToken}`;
            prevRequest.headers["Access-Control-Allow-Origin"] = "*";
            return instance(prevRequest);
          } else if (HttpStatusCode === 401 && prevRequest?.sent) {
            destroyCookie({}, "neo.client", {path: "/"});
            const selectedClient: Client = await getNeoCookie("neo.client");
            if (selectedClient == null) {
              signOut();
            }
          } else if (HttpStatusCode === 422 || HttpStatusCode === 400) {
            toast.error(neoResponse?.message!);
            return Promise.reject(neoResponse);
          } else {
            toast.error(SystemError);
            return Promise.reject(neoResponse);
          }
        }
      }
      toast.error(SystemError);
      return Promise.reject(error);
    }
  );
  return instance;
};

const fetchSession = async () => {
  if (lastSession) {
    return lastSession;
  }
  if (!sessionPromise) {
    sessionPromise = (async () => {
      try {
        const session = await getSession();
        lastSession = session;
        return session;
      } catch (error) {
        console.error("Error fetching session:", error);
        throw error;
      } finally {
        sessionPromise = null;
      }
    })();
  }
  return sessionPromise;
};

export default axiosInstance;
